import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const pastPrecipHoursState = atom<number | undefined>({
    key: 'pastPrecipHoursState',
    default: 48,
    effects_UNSTABLE: [persistAtom]
});
